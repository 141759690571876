<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-user"></i>&nbsp;
      <span>橱窗管理 - {{ showcasetitle }}</span>
    </template>
    <div v-if="isload" class="content">
      <div class="content-header">
        <el-tabs
          v-model="activeName"
          type="border-card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="广告位设置" name="ad">
            <el-row>
              <el-col>
                <el-button type="success" class="f-right" @click="addItem('ad')"
                  >新增广告</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table
                class="pdt-list"
                :data="ad"
                width="100%"
                v-loading="loading"
                element-loading-text="拼命加载中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              >
                <el-table-column label="广告图" width="200">
                  <template #default="scope">
                    <el-image class="pdt-thumb" :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="360"
                  class-name="cate_control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods(scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('ad', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button type="text" @click="addItem('ad', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="moveUp('ad', scope.$index)"
                      >上移</el-button
                    >
                    <el-button type="text" @click="moveDown('ad', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('ad', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="菜单管理" name="menus">
            <el-row>
              <el-col>
                <el-tag size="small" type="danger"
                  >菜单项大于 1 才会显示，最多添加 10 个</el-tag
                >
                <el-button type="success" class="f-right" @click="addMenu()"
                  >添加菜单</el-button
                >
              </el-col>
            </el-row>
            <div class="content-main">
              <el-table
                :data="menus"
                v-loading="loading"
                element-loading-text="拼命加载中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                class="pdt-list"
              >
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="360"
                  class-name="cate_control"
                >
                  <template #default="scope">
                    <el-button type="text" @click="selGoods(scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('menus', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button type="text" @click="addMenu(scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('menus', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('menus', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('menus', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <el-button @click="goBack()" style="margin-top: 20px">返 回</el-button>
    </div>
  </el-card>

  <el-dialog
    title="广告编辑器"
    v-model="isshow.ad"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.ad"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="广告图" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >广告图最佳尺寸：750px × 200px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.ad = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('ad')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="橱窗编辑器"
    v-model="isshow.menus"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.menus"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.menus = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('menus')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <LinkBlock
    :linktype="curlinktype"
    :showlink="showlink"
    :selgoods="selgoods"
    :goodsids="curgoodsid"
    :curlink="curlink"
    v-on:close="onClose"
    v-on:submit="setLink"
  ></LinkBlock>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import pageconst from "@/pageconst";
import LinkBlock from "@/components/LinkBlock";

export default {
  data() {
    return {
      isload: false,
      uploadAction: pageconst.upload_pdt_detail_url,
      activeName: "ad",
      pagename: "",
      blockindex: "",
      config: [],
      curindex: 0,
      ad: [],
      menus: [],
      isshow: {
        ad: false,
        menus: false,
      },
      curform: [],
      orgform: "",
      rules: {
        ad: {
          title: [
            { required: true, message: "请输入广告图标题", trigger: "blur" },
          ],
          src: [{ required: true, message: "请上传广告图", trigger: "blur" }],
        },
        menus: {
          title: [
            { required: true, message: "请输入菜单标题", trigger: "blur" },
          ],
        },
      },
      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
        {
          value: "goodslist",
          label: "商品集合页",
        },
        {
          value: "link",
          label: "链接",
        },
      ],
      selgoods: "radio",
      curlinktype: "notlink",
      showlink: false,
      curgoodsid: "",
      curlink: "",
      curblocktype: "",
      goodslist: [],
      showcasetitle: "",
    };
  },
  components: {
    LinkBlock,
  },
  methods: {
    goBack() {
      history.go(-1);
    },
    handleClick({ props }) {
      let { name } = props;
      this.curtab = name;
    },

    selGoods(index) {
      let that = this;
      this.$router.push({
        path: "/page/goodsmanage",
        query: {
          pagename: that.pagename,
          blockindex: that.blockindex,
          type: "showcase",
          subtype: this.activeName,
          index: this.curindex,
          itemindex: index,
        },
      });
    },

    onCloseBlock() {
      this.isshow[this.curblocktype] = false;
      if (this.orgform != "") {
        this[this.activeName][this.curform.index] = JSON.parse(this.orgform);
      }
    },
    saveConfig() {
      if (this.curform.index !== "") {
        if (this.activeName == "ad") this.ad[this.curform.index] = this.curform;
        if (this.activeName == "menus")
          this.menus[this.curform.index] = this.curform;
      } else {
        if (this.activeName == "ad") this.ad.push(this.curform);
        if (this.activeName == "menus") this.menus.push(this.curform);
      }

      let pagename = this.pagename;
      if (pagename == "index") {
        this.config.showcase.value[this.curindex].ad = this.ad;
        this.config.showcase.value[this.curindex].menus = this.menus;
      } else if (pagename.indexOf("compage_") !== -1) {
        this.config.blocks[this.blockindex].items[this.curindex].ad = this.ad;
        this.config.blocks[this.blockindex].items[this.curindex].menus =
          this.menus;
      }

      this.axios
        .post(
          pageconst.pagemanage + "?entry=saveconfig&pagename=" + pagename,
          JSON.stringify(this.config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data }) => {
          if (
            data == "SUCCESS" ||
            (pagename.indexOf("compage_") !== -1 &&
              data.indexOf("SUCCESS|compage_") !== -1)
          ) {
            console.log("保存成功");
          } else {
            ElMessage.error({
              showClose: true,
              message: data,
            });
            return false;
          }
        });
    },
    addItem(type, index = "") {
      if (index !== "") {
        this.curform = this[type][index];
        this.curform.index = index;

        this.curlinktype = this.curform.linktype;

        this.orgform = JSON.stringify(this[type][index]);
      } else {
        if (this.ad.length == 3) {
          ElMessage.warning({
            message: "最多只能添加3个广告图",
            type: "warning",
            center: true,
          });
          return;
        }
        this.curform = {};
        this.curform.index = "";
        this.curform.status = 1;
        this.curform.linktype = "notlink";
        this.curform.linkname = "不跳转";

        this.orgform = "";
      }

      this.selgoods = "radio";
      this.curblocktype = type;
      this.showlink = false;

      for (var key in this.isshow) {
        if (key == type) this.isshow[key] = true;
        else this.isshow[key] = false;
      }
    },
    delItem(type, index) {
      var that = this,
        curblock = this[type],
        title = curblock[index].title;
      ElMessageBox.confirm("您确定要删除 " + title + " 吗？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          curblock.splice(index, 1);
          that[type] = curblock;

          that.saveConfig();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveUp(type, index) {
      var curblock = this[type];
      if (index != 0) {
        this[type] = this.swapArray(curblock, index, index - 1);
      }

      this.saveConfig();
    },
    moveDown(type, index) {
      var curblock = this[type];
      if (index + 1 != curblock.length) {
        this[type] = this.swapArray(curblock, index, index + 1);
      }

      this.saveConfig();
    },
    imageUploadChange(file) {
      console.log("image upload change ===>");
      console.log(file);

      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        this.$message.error("产品缩略图大小不能超过 2MB!");
        return false;
      }

      if (isSubmit) this.$refs.imageUpload.submit();
    },
    imageUploadSuccess(res) {
      console.log("image upload success ===> " + res.url);
      this.curform.src = res.url;
    },
    imgUploadError(err) {
      console.log("err==>");
      console.log(err);
    },
    saveForm(type) {
      // 保存当前模块数据
      var that = this;
      console.log(this.curform);
      this.$refs["dataform"].validate((res) => {
        if (res) {
          var curindex = that.curform.index;
          console.log(curindex);
          delete that.curform.index;
          if (curindex !== "") {
            // 更新当前模块
            that[type][curindex] = that.curform;
          } else {
            // 新增模块
            that[type].push(that.curform);
          }
          that.saveConfig();
          that.isshow[type] = false;
        }
      });
    },
    changeLinkType(e) {
      //console.log(e);
      this.curlinktype = e;
      this.curform.link = "";
      if (e != "notlink" && e != "goodslist" && e != "link") {
        this.selgoods = "radio";
        this.curform.linkname = "";
        this.showlink = true;
      } else if (e == "goodslist") {
        this.curform.linkname = "商品集合页";
      } else if (e == "link") {
        this.curform.linkname = "自定义链接";
      } else {
        this.curform.linkname = "不跳转";
      }
    },
    onClose(isclose) {
      this.showlink = isclose;
    },
    onCloseBlockEditor(isclose, blockdata = null) {
      console.log(blockdata);
      this.isshow[this.curblocktype] = isclose;
      if (blockdata != null) {
        delete this.curform.index;
        this.curform = blockdata;
        this.saveConfig();
      }
    },
    setLink(link, linkname, goodsid = "") {
      if (this.curtab == "goods") {
        this.axios
          .get(
            pageconst.pagemanage +
              "?entry=getgoodslist&goodsids=" +
              JSON.stringify(goodsid) +
              "&pagesize=1000",
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then(({ data }) => {
            var { list } = data;

            let curgoodsids = [].concat(this.goodsids);
            for (let i = 0; i < list.length; i++) {
              let inarray = false;
              for (let j = 0; j < curgoodsids.length; j++) {
                if (curgoodsids[j].id == list[i].ID) {
                  inarray = true;
                }
              }

              if (!inarray) {
                let obj = new Object();
                obj.id = list[i].ID;
                obj.status = 1;
                curgoodsids.push(obj);
              }
            }

            this.goodsids = curgoodsids;

            this.getGoodsList();

            this.saveConfig();
          });
      } else {
        this.curform.link = link;
        this.curform.linkname = linkname;
        this.curform.goodsid = goodsid;
      }
    },
    selectLink() {
      this.curlinktype = this.curform.linktype;
      this.curgoodsid = this.curform.goodsid;
      this.curlink = this.curform.link;
      this.showlink = true;
    },

    getGoodsList() {
      this.axios
        .get(
          pageconst.pagemanage +
            "?entry=getgoodslistbyids&goodsids=" +
            JSON.stringify(this.goodsids),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => {
          let { list } = data;
          this.goodslist = list;
        });
    },

    addMenu(index = "") {
      if (index !== "") {
        this.curform = this.menus[index];
        this.curform.index = index;
        this.orgform = JSON.stringify(this.menus[index]);
      } else {
        if (this.menus.length == 10) {
          ElMessage.warning({
            message: "最多只能添加 10 个菜单项",
            type: "warning",
            center: true,
          });
          return;
        }

        this.curform = {};
        this.curform.status = 0;
        this.curform.index = "";
        this.orgform = "";
      }

      this.isshow.menus = true;
    },

    updateStatus(type, index) {
      let curstatus = this[type][index].status,
        title = this[type][index].title;

      if (type == "goodsids") title = this.goodslist[index].PdtName;

      if (curstatus == 1) {
        // 询问
        let that = this;
        ElMessageBox.confirm("您确定要停用 " + title + " 吗？", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that[type][index].status = 0;
            if (type == "goodsids") that.goodslist[index].status = 0;
            that.saveConfig();
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this[type][index].status = 1;
        if (type == "goodsids") this.goodslist[index].status = 1;
        this.saveConfig();
      }
    },
  },
  created: function () {
    let { pagename, blockindex, type, index } = this.$route.query;
    this.activeName = type;
    this.curindex = index;
    this.pagename = pagename;
    this.blockindex = blockindex;

    this.axios
      .get(pageconst.pagemanage + "?entry=getconfig&pagename=" + pagename, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        var { config } = data;
        this.config = config;
        if (pagename == "index") {
          this.ad = config.showcase.value[index].ad
            ? config.showcase.value[index].ad
            : [];
          this.menus = config.showcase.value[index].menus
            ? config.showcase.value[index].menus
            : [];
          this.showcasetitle = config.showcase.value[index].title
            ? config.showcase.value[index].title
            : "";
        } else if (pagename.indexOf("compage_") !== -1) {
          this.ad = config.blocks[blockindex].items[index].ad
            ? config.blocks[blockindex].items[index].ad
            : [];
          this.menus = config.blocks[blockindex].items[index].menus
            ? config.blocks[blockindex].items[index].menus
            : [];
          this.showcasetitle = config.blocks[blockindex].items[index].title
            ? config.blocks[blockindex].items[index].title
            : "";
        }
        this.isload = true;
      });
  },
};
</script>

<style scoped>
.pdt-list {
  margin-top: 20px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

>>> .cate_control {
  text-align: right;
}

>>> .el-table td {
  padding: 8px 0;
}

>>> .el-table .cell {
  max-height: 50px;
}

.cell .el-button {
  margin-right: 15px;
  margin-left: 0;
}
.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}

.pdt-thumb {
  height: 50px;
  padding: 2px;
  border: 1px solid #ddd;
}
>>> .pdt-thumb img {
  width: auto;
  height: 100%;
}

.readinput,
.sel-btn {
  margin-top: 10px;
}
.sel-btn {
  margin-left: 10px;
}

.dataform >>> .avatar-uploader {
  line-height: 0;
}

.dataform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform >>> .avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}
</style>